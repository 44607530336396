import React, { useEffect, useState } from 'react';
import { FaPlus, FaMapMarkedAlt, FaRegWindowClose } from 'react-icons/fa';

export const DrillholeList = ({ projectName, drillholes, handleDrillholeSelect, handleMapUpdated }) => {
    const [hideNewDrillholeDetails, setHideNewDrillholeDetails] = useState(true);
    const [newDrillholeName, setNewDrillholeName] = useState('');
    const [newDrillholeRig, setNewDrillholeRig] = useState('');
    const [newDrillholeCollarX, setNewDrillholeCollarX] = useState('');
    const [newDrillholeCollarY, setNewDrillholeCollarY] = useState('');
    const [newDrillholeCollarZ, setNewDrillholeCollarZ] = useState('');
    const [newDrillholeDescription, setNewDrillholeDescription] = useState('');
    const [drillholeFilter, setDrillholeFilter] = useState('');
    const [drillholeFilteredList, setDrillholeFilteredList] = useState(drillholes);

    const toggleNewDrillholeDetails = () => {
        setHideNewDrillholeDetails(!hideNewDrillholeDetails);
    }

    const handleNewDrillholeNameChange = (event) => {
        setNewDrillholeName(event.target.value);
    }

    const handleNewDrillholeRigChange = (event) => {
        setNewDrillholeRig(event.target.value);
    }

    const handleNewDrillholeCollarXChange = (event) => {
        setNewDrillholeCollarX(event.target.value);
    }

    const handleNewDrillholeCollarYChange = (event) => {
        setNewDrillholeCollarY(event.target.value);
    }

    const handleNewDrillholeCollarZChange = (event) => {
        setNewDrillholeCollarZ(event.target.value);
    }

    const handleNewDrillholeDescriptionChange = (event) => {
        setNewDrillholeDescription(event.target.value);
    }

    const handleNewDrillholeSubmit = (event) => {
        event.preventDefault();
        let x = parseFloat(newDrillholeCollarX);
        let y = parseFloat(newDrillholeCollarY);
        let z = parseFloat(newDrillholeCollarZ);
        if (newDrillholeName === '') {
            return;
        }
        if (isNaN(x)) {
            x = 0;
        }
        if (isNaN(y)) {
            y = 0;
        }
        if (isNaN(z)) {
            z = 0;
        }
        const newDrillhole = {
            name: newDrillholeName,
            rigId: newDrillholeRig,
            location: {
                x: x,
                y: y,
                z: z
            },
            description: newDrillholeDescription
        };
        console.log(JSON.stringify(newDrillhole, null, 2));
        fetch('api/projects/newdrillhole', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Project': projectName
            },
            body: JSON.stringify(newDrillhole)
        })
            .then(response => response.json())
            .then(data => {
                setNewDrillholeName('');
                setNewDrillholeRig('');
                setNewDrillholeCollarX('');
                setNewDrillholeCollarY('');
                setNewDrillholeCollarZ('');
                setNewDrillholeDescription('');
                setHideNewDrillholeDetails(true);
                handleMapUpdated(data);
                //handleDrillholeSelect(data.name);
            });
    }

    const handleDrillholeFilterChange = (event) => {
        setDrillholeFilter(event.target.value);
        const filteredList = drillholes.filter((drillhole) => {
            return drillhole.name.toLowerCase().includes(event.target.value.toLowerCase());
        });
        setDrillholeFilteredList(filteredList);
    }

    useEffect(() => {
        setDrillholeFilter('');
        setDrillholeFilteredList(drillholes);
    }, [drillholes])

    let newDrillholeDetails = '';

    if (!hideNewDrillholeDetails) {
        newDrillholeDetails = (
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">New Drillhole</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={handleNewDrillholeSubmit}>
                        <div className="form-group">
                            <label htmlFor="newDrillholeName">Name:</label>
                            <input type="text" className="form-control" id="newDrillholeName" name="newDrillholeName" value={newDrillholeName} onChange={handleNewDrillholeNameChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="newDrillholeRig">Rig:</label>
                            <input type="text" className="form-control" id="newDrillholeRig" name="newDrillholeRig" value={newDrillholeRig} onChange={handleNewDrillholeRigChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="newDrillholeCollarX">Collar X:</label>
                            <input type="number" className="form-control" id="newDrillholeCollarX" name="newDrillholeCollarX" value={newDrillholeCollarX} placeholder="0.0" onChange={handleNewDrillholeCollarXChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="newDrillholeCollarY">Collar Y:</label>
                            <input type="number" className="form-control" id="newDrillholeCollarY" name="newDrillholeCollarY" value={newDrillholeCollarY} placeholder="0.0" onChange={handleNewDrillholeCollarYChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="newDrillholeCollarZ">Collar Z:</label>
                            <input type="number" className="form-control" id="newDrillholeCollarZ" name="newDrillholeCollarZ" value={newDrillholeCollarZ} placeholder="0.0" onChange={handleNewDrillholeCollarZChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="newDrillholeDescription">Description:</label>
                            <textarea className="form-control" id="newDrillholeDescription" name="newDrillholeDescription" value={newDrillholeDescription} onChange={handleNewDrillholeDescriptionChange} />
                        </div>
                        <button type="submit" className="btn btn-secondary">Create</button>
                    </form>
                </div>
            </div>
        );
    }

    let drillholeListContents = '';

    if (drillholeFilteredList) {
        drillholeListContents = (
            <div className="list-group overflow-auto" style={{ maxHeight: 400 }}>
                {drillholeFilteredList.map(drillhole =>
                    <button className="list-group-item list-group-item-action" key={drillhole.name} onClick={() => handleDrillholeSelect(drillhole.name)}>{drillhole.name}</button>
                )}
            </div>
        );
    }

    return (
        <div>
            <div className="d-flex">
                <h3>Drillholes</h3>
                <button className="btn btn-primary ms-auto" style={{ marginRight: 10 }}><FaMapMarkedAlt /> Map</button>
                <button className="btn btn-primary" onClick={toggleNewDrillholeDetails}>{hideNewDrillholeDetails ? <span><FaPlus /> New</span> : <span><FaRegWindowClose /> Cancel</span>}</button>
            </div>
            <br />
            {newDrillholeDetails}
            <input type="text" className="form-control" id="drillholeFilter" name="drillholeFilter" value={drillholeFilter} onChange={handleDrillholeFilterChange} placeholder="Search" />
            <br />
            {drillholeListContents}
        </div>
    );
}