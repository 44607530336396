import React, { useState } from 'react';
import { FaPlus, FaRegWindowClose } from 'react-icons/fa';


export const ProjectList = ({  projects, handleProjectSelect, handleMapUpdated }) => {
    const [hideNewProjectDetails, setHideNewProjectDetails] = useState(true);
    const [newProjectName, setNewProjectName] = useState('');
    const [newProjectDescription, setNewProjectDescription] = useState('');
    const [selectedProject, setSelectedProject] = useState('');

    const toggleNewProjectDetails = () => {
        setHideNewProjectDetails(!hideNewProjectDetails);
    }

    const handleNewProjectSubmit = (event) => {
        event.preventDefault();
        if (newProjectName === '') {
            return;
        }
        const newProject = {
            name: newProjectName,
            description: newProjectDescription
        };
        fetch('api/projects/newproject', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newProject)
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', JSON.stringify(data, null, 2));
                handleMapUpdated(data);
            });
        setNewProjectName('');
        setNewProjectDescription('');
        setHideNewProjectDetails(true);
    }

    const handleNewProjectNameChange = (event) => {
        setNewProjectName(event.target.value);
    }

    const handleNewProjectDescriptionChange = (event) => {
        setNewProjectDescription(event.target.value);
    }

    const handleSelectionChanged = (event) => {
        if (event.target.value === '') {
            return;
        }
        setSelectedProject(event.target.value);
        handleProjectSelect(event.target.value);
    }

    let newProjectDetails = '';

    if (!hideNewProjectDetails) {
        newProjectDetails = (
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">New Project</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={handleNewProjectSubmit}>
                        <div className="form-group">
                            <label htmlFor="projectName">Project Name</label>
                            <input type="text" className="form-control" id="projectName" onChange={handleNewProjectNameChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="description">Description (optional)</label>
                            <textarea className="form-control" id="description" rows="3" onChange={handleNewProjectDescriptionChange}></textarea>
                        </div>
                        <button type="submit" className="btn btn-secondary">Create</button>
                    </form>
                </div>
            </div>
        );
    }


    let projectListContents = '';

    if (projects && projects.length > 0) {
        projectListContents = (
            <select className="form-select form-select-sm" onChange={handleSelectionChanged} value={selectedProject}>
                <option value=''>Select a project</option>
                {projects.map((project, index) =>
                    <option key={project.name} value={project.name}>{project.name}</option>
                )}
            </select>
        );

    }

    return (
        <div>
            <div className="d-flex">
                <h3>Projects</h3>
                <button className="btn btn-primary ms-auto" onClick={toggleNewProjectDetails} >{hideNewProjectDetails ? <span><FaPlus /> New</span> : <span><FaRegWindowClose /> Cancel</span>}</button>
            </div>
            <br />
            {newProjectDetails}
            {projectListContents}
        </div>
    );
}