import React, { UseState } from 'react';
import { DrillholeList } from './DrillholeList';
import { FaTrash } from 'react-icons/fa';



export const ProjectSummary = ({ project, handleDrillholeSelect, handleMapUpdated, handleProjectDelete }) => {

    let contents = <p><em>select a project</em></p>;

    if (project.name) {
        contents = (
            <div>
                <div className="d-flex">
                    <h3>{project.name}</h3>
                    <button className="btn btn-danger ms-auto" onClick={() => handleProjectDelete(project.name)}><FaTrash /> Delete</button>
                </div>
                <p>{project.description}</p>
                <p>created: {new Date(project.created).toLocaleString()}</p>
                <DrillholeList projectName={project.name} drillholes={project.drillholes} handleDrillholeSelect={handleDrillholeSelect} handleMapUpdated={handleMapUpdated} />
            </div>
        );
    }

    return (
        <div>
            {contents}
        </div>
    );
}