import React, { useState, useEffect } from 'react';
import { SurveyPreviewTable } from '../controls/SurveyPreviewTable';
import { SurveyApprovalForm } from '../controls/SurveyApprovalForm';
import {checkLoggedIn} from '../../functions/CookieHelper';

export const SurveyApproval = () => {
    const [loggedIn, setLoggedIn] = React.useState(checkLoggedIn());
    const [customerMap, setCustomerMap] = useState([]);
    const [selectedSurvey, setSelectedSurvey] = useState({});

    const handleSurveySelect = (surveyReference) => {
        setSelectedSurvey(surveyReference);
    }

    const handleApproveSubmit = (selectedProject, selectedDrillhole) => {
        fetch('api/projects/approvesurvey', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                key: selectedSurvey.key,
                project: selectedProject,
                drillhole: selectedDrillhole
            })
        })
            .then(response => response.json())
            .then(data => {

                setCustomerMap(data);
                setSelectedSurvey({});
            });
    }



    useEffect(() => {
        if (!loggedIn) {
            window.location.href = '/login';
        }
        else {
            const fetchData = async () => {
                const res = await fetch('api/projects', {
                    method: 'GET'
                });
                const data = await res.json();
                setCustomerMap(data);
            }
            fetchData();
        }
    }, []);


    return (
        <div>
            <h1>Survey Approval</h1>
            <hr />
            <div className="row">
                <div className="col-lg-3">
                    <h3>Unallocated Surveys</h3>
                    <div className="list-group">
                        {customerMap.unallocatedSurveys && customerMap.unallocatedSurveys.map((survey) => {
                            return (
                                <button key={survey.key}
                                    onClick={() => handleSurveySelect(survey)}
                                    className="list-group-item list-group-item-action d-flex justify-content-between">
                                    <h5>{survey.fileName}</h5>
                                    <h6>{new Date(survey.uploaded).toLocaleDateString()}</h6>
                                </button>
                            );
                        })}
                    </div>
                </div>
                <div className="col-lg-6">
                    {selectedSurvey.key && < SurveyPreviewTable surveyReference={selectedSurvey} />}
                </div>
                <div className="col-lg-3">
                    <h3>{selectedSurvey.fileName}</h3>
                    <p>{selectedSurvey.description}</p>
                    {selectedSurvey.key && < SurveyApprovalForm
                        customerMap={customerMap}
                        selectedSurvey={selectedSurvey}
                        handleApproveSubmit={handleApproveSubmit}
                    />}
                </div>
            </div>
        </div>
    );

}