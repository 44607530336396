import React, { useEffect, useState } from 'react';
import { InfoTab } from './InfoTab';
import { TableTab } from './TableTab';
import { ChartsTab } from './ChartsTab';
import { FaChartBar, FaTable, FaInfo } from 'react-icons/fa';

export const DrillholeSummary = ({ drillhole }) => {
    const [selectedSurvey, setSelectedSurvey] = useState({});
    const [tabContent, setTabContent] = useState('info');

    const handleSurveySelect = (surveyReference) => {
        setSelectedSurvey(surveyReference);
    }

    useEffect(() => {
        setSelectedSurvey({});
        setTabContent('info');
    }, [drillhole]);

    const renderTabContent = () => {
        switch (tabContent) {
            case 'info':
                return <InfoTab drillhole={drillhole} handleDrillholeDelete={() => { alert("Woah that seems pretty drastic!") }} handleDrillholeEdit={() => { alert("Coming soon") }} />;
            case 'table':
                return <TableTab drillhole={drillhole} />;
            case 'charts':
                return <ChartsTab drillhole={drillhole} />;
            default:
                return <p>Info</p>;
        }
    }


    let contents = '';

    if (drillhole.name) {
        contents = (
            <div className="row">
                <div className="col-lg-12">
                    <div className="d-flex">
                        <h3>{drillhole.name}</h3>
                        <button className="btn btn-primary ms-auto" style={{ marginRight: 10 }} onClick={() => { handleSurveySelect({}); setTabContent('info'); }}><FaInfo /> Info</button>
                        <button className="btn btn-primary" disabled={drillhole.surveys.length < 1} style={{ marginRight: 10 }} onClick={() => { handleSurveySelect({}); setTabContent('table'); }}><FaTable /> Table</button>
                        <button className="btn btn-primary" disabled={drillhole.surveys.length < 1} style={{ marginRight: 10 }} onClick={() => { handleSurveySelect({}); setTabContent('charts'); }}><FaChartBar /> Charts</button>
                    </div>
                </div>
                <hr style={{ marginTop: 10 }} />
                <div className="col-lg-12">
                    {renderTabContent()}
                </div>
            </div>
        );
    }

    return (
        <div>
            {contents}
        </div>
    );
}