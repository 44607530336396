import React, { useState, useEffect } from 'react';
import { FaUser } from 'react-icons/fa';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { checkLoggedIn } from '../functions/CookieHelper';
import './NavMenu.css';


export const NavMenu = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(checkLoggedIn());
    const [user, setUser] = useState({});
    const [collapsed, setCollapsed] = useState(true);
    const pathName = useLocation().pathname;

    useEffect(() => {
        setCollapsed(true);
    }, [pathName]);

    useEffect(() => {
        if (isLoggedIn) {
            const loadUser = async () => {
                const response = await fetch('api/identity/getuser');
                const data = await response.json();
                setUser(data);
                console.log('user', data);
            }
            loadUser();
        }
    }, [isLoggedIn]);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    }

    let navContents = '';
    let userName = '';
    if (user) {
        userName = user.customer;
    }

    switch (pathName) {
        case '/overview':
        case '/approval':
        case '/upload':
            navContents = (<ul className="navbar-nav flex-grow">
                <NavItem>
                    <NavLink tag={Link} className="text-light" to="/">Home</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} className="text-light" to="/overview">Projects</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} className="text-light" to="/approval">Approval</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} className="text-light" to="/upload">Upload</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} className="text-light" to="/logout">{userName && userName + ' '}Logout</NavLink>
                </NavItem>
                <NavItem>
                    <div style={{ width: 20 }} />
                </NavItem>
            </ul>);
            break;
        case '/hammertrak':
        case '/hammertrak/portal':
            navContents = (<ul className="navbar-nav flex-grow">
                <NavItem>
                    <NavLink tag={Link} className="text-light" to="/">Home</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} className="text-light" to="/logout">{userName && userName + ' '} Logout</NavLink>
                </NavItem>
                <NavItem>
                    <div style={{ width: 20 }} />
                </NavItem>
            </ul>);
            break;
        default:
            navContents = (<ul className="navbar-nav flex-grow">
                <NavItem>
                    <NavLink tag={Link} className="text-light" to="/overview">Projects</NavLink>
                </NavItem>
                {user && user.customer === 'Capital' && (
                    <NavItem>
                        <NavLink tag={Link} className="text-light" to="/hammertrak">HammerTrak</NavLink>
                    </NavItem>
                )}
                <NavItem>
                    <NavLink tag={Link} className="text-light" to="/logout">{userName && userName + ' '}Logout</NavLink>
                </NavItem>
                <NavItem>
                    <div style={{ width: 20 }} />
                </NavItem>
            </ul>);
            break;
    }




    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container-fluid="true" dark style={{ backgroundColor: '#1d1f4f' }}>
                <NavbarBrand><img src="/images/drilltrak_white_40.png" alt="DrillTrak" /></NavbarBrand>
                <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
                    {isLoggedIn && navContents}
                </Collapse>
            </Navbar>
        </header>
    );

}
