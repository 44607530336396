import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polyline, useMap } from 'react-leaflet';

export const HTMapView = ({ locations }) => {
    const [lastLocation, setLastLocation] = useState(locations.length > 0 ? locations[locations.length - 1] : null);
    const [rigLocations, setRigLocations] = useState(locations);
    console.log('locationData', lastLocation);
    function ChangeMapView({ coords }) {
        const map = useMap();
        map.setView(coords, map.getZoom());

        return null;
    }

    useEffect(() => {
        setLastLocation(locations.length > 0 ? locations[locations.length - 1] : null);
        setRigLocations(locations);
    }, [locations]);

    if (lastLocation == null) {
        return <p>Loading ...</p>;
    }

    return (
        <div className="card">
            <div className="card-header">
                <h4>Map</h4>
            </div>
            <div className="card-body">
                {lastLocation && <p>Last seen at {lastLocation.latitude.toFixed(4)}, {lastLocation.longitude.toFixed(4)} on {lastLocation.recordTime.split('T')[0].replaceAll('-', '/')}</p>}
                {lastLocation && <div style={{ width: '100%', height: 400 }}>
                    <MapContainer center={[lastLocation.latitude, lastLocation.longitude]} zoom={12}>
                        <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                        />
                        {rigLocations.map((location, index) => {
                            return <Marker key={index} position={[location.latitude, location.longitude]}>
                                <Popup>
                                    {location.latitude.toFixed(4)}<br />
                                    {location.longitude.toFixed(4)}<br />
                                    {location.recordTime}
                                    {/*{location.recordTime.split('T')[0].replaceAll('-', '/')}*/}
                                </Popup>
                            </Marker>
                        })
                        }
                        <ChangeMapView coords={[lastLocation.latitude, lastLocation.longitude]} />
                    </MapContainer>
                </div>
                }
            </div>
        </div>
    );
}