import React, { useState, useEffect } from 'react';

export const SurveyPreviewTable = ({ surveyReference }) => {
    const [loading, setLoading] = useState(true);
    const [survey, setSurvey] = useState({});

    useEffect(() => {
        const loadSurvey = async () => {
            const response = await fetch('api/surveys?key=' + surveyReference.key, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            setSurvey(data);
        }
        if (surveyReference.key) {

            loadSurvey();
            setLoading(false);
        }
    }, [surveyReference]);

    return (
        <div>
            <h2>Survey Preview</h2>
            <h4>{survey.name}</h4>
            <p>{survey.description}</p>
            <h5>Survey tool: {survey.surveyTool}</h5>
            {survey.surveyLines && (
                <div className="overflow-auto" style={{ maxHeight: 500 }}>
                    <table className="table table-striped table-sm">
                        <thead>
                            <tr>
                                <th>Depth</th>
                                <th>Inclination</th>
                                <th>Azimuth</th>
                                <th>North</th>
                                <th>East</th>
                                <th>Tvd</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? <tr><td colSpan="7">Loading...</td></tr> : survey.surveyLines.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{row.depth.toFixed(2)}</td>
                                        <td>{row.inclination.toFixed(2)}</td>
                                        <td>{row.azimuth.toFixed(2)}</td>
                                        <td>{row.northing.toFixed(2)}</td>
                                        <td>{row.easting.toFixed(2)}</td>
                                        <td>{row.tvd.toFixed(2)}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}