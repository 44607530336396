import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { checkLoggedIn } from '../../functions/CookieHelper';

export const HammerTrak = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(checkLoggedIn());
    const [softwareVersion, setSoftwareVersion] = useState('loading ...');
    const [releaseDate, setReleaseDate] = useState('');

    useEffect(() => {
        const loadVersion = async () => {
            const response = await fetch('api/hammertrak/version');
            const data = await response.text();
            let parts = data.split('\n');
            if (parts.length > 1) {
                setSoftwareVersion(parts[0]);
                setReleaseDate(parts[1]);
            }
        }
        loadVersion();
    }, []);



    return (
        <div>
            <div className="container">
                <div className="row">
                    <h1>HammerTrak</h1>
                </div>

                <div className="row">
                    <div className="col-md">
                        <div className="card">
                            <div className="card-header">
                                <h3>Desktop application</h3>
                            </div>
                            <div className="card-body">
                                <h4 className="card-title">
                                    Version: {softwareVersion}
                                    {releaseDate && <span> (released: {releaseDate})</span>}
                                </h4>
                                <p className="card-text">Download the desktop application for HammerTrak.</p>
                                <a href="api/hammertrak/download" target="_blank" rel="noopener noreferrer"><button className="btn btn-secondary">Download</button></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="card">
                            <div className="card-header">
                                <h3>Analysis</h3>
                            </div>
                            <div className="card-body">
                                <h4 className="card-title">HammerTrak analytics portal</h4>
                                <p className="card-text">Log in to access the HammerTrak analytics portal.</p>
                                {isLoggedIn ?
                                    (<Link to="/hammertrak/portal" className="btn btn-secondary">Go to HammerTrak Portal</Link>)
                                    :
                                    (<Link to="/login?returnPage=/hammertrak/portal" className="btn btn-secondary">Log in to access portal</Link>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md">
                        <div className="card">
                            <div className="card-header">
                                <h3>Quick Start</h3>
                            </div>
                            <div className="card-body">
                                <h4 className="card-title">Drill site interface</h4>
                                <p>A computer or tablet running Windows is required on site. This device must have Bluetooth capabilities and would ideally have live internet access.
                                    Download the desktop application via the link above and install it on the device. Once installed, launch the application to initiate a connection with the HammerTrak module.<br />
                                    The module should be mounted to the drill pipe using its built-in magnetic base, on to the spanner flats. Push the button on the lower face to power the module on.<br />
                                    The application will automatically connect to the module and begin logging data. The application will also display a live feed of the data being collected by the module.<br />
                                    If an internet connection is available, the application will upload the data to the HammerTrak portal for analysis. Data will be uploaded every five minutes.
                                </p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="card-title">Application launch</h4>
                                        <img src="/images/ht_search.png" className="img-fluid" alt="HammerTrak module" />
                                        <p>The application will automatically search for a connectable module and pair with it, once found. The serial number of the module will be displayed in the top left box.
                                            The computer's internet connection is periodically checked and the status of any wired or wireless connection will be displayed in the upper right box.
                                            Alongisde this is an input box for the user to enter an identifier for the rig or hole being drilled. This identifier will be used to group the data in the HammerTrak portal.
                                        </p>
                                    </div>
                                    <div className="col-md-6">
                                        <h4 className="card-title">Connected and drilling</h4>
                                        <img src="/images/ht_run.png" className="img-fluid" alt="HammerTrak module" />
                                        <p>Once connected, the application will display the live data feed from the module. The data is displayed in real time and is updated two times per second.
                                            Drill pipe rotation is displayed in the left hand graphic, whilst the drilling efficiency is displayed in the right hand graphic.
                                            Below the efficiency graphic is a frequency plot, showing the frequency response of the drill pipe, with the primary frequency highlighted in green.
                                            The drilling efficiency is calculated as the ratio of the integral of the primary frequency to the integral of the non-primary frequencies.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}