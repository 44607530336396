import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import 'echarts-gl';

export const Line3DChart = ({ title, xAxisName, yAxisName, zAxisName, seriesCollection }) => {
    const handleComponentLoaded = () => {
        console.log('Line3DChart component loaded');
        var main = document.getElementById('main');
        if (!main) {
            return;
        }
        var xValues = [];
        seriesCollection.forEach((series) => {
            series.points.forEach((point) => {
                xValues.push(point[0]);
            });
        });
        var yValues = [];
        seriesCollection.forEach((series) => {
            series.points.forEach((point) => {
                yValues.push(point[1]);
            });
        });
        var zValues = [];
        seriesCollection.forEach((series) => {
            series.points.forEach((point) => {
                zValues.push(point[2]);
            });
        });
        //var bottomHoleDepth = Math.min(...zValues);
        var min = Math.min(...xValues, ...yValues, ...zValues);
        var max = Math.max(...xValues, ...yValues, ...zValues);
        var range = max - min;
        var xRange = Math.max(...xValues) - Math.min(...xValues);
        var yRange = Math.max(...yValues) - Math.min(...yValues);
        var xAxisMin = Math.min(...xValues) - (range - xRange) / 2;
        var yAxisMin = Math.min(...yValues) - (range - yRange) / 2;

        var chart = echarts.init(document.getElementById('main'));
        var option = {
            title: {
                text: title,
                left: 'center'
            },
            tooltip: {},
            toolbox: {
                feature: {
                    saveAsImage: {},
                    dataZoom: {},
                    restore: {}
                }
            },
            //visualMap: {
            //    show: false,
            //    min: bottomHoleDepth,
            //    max: 0,
            //    inRange: {
            //        color: ['#1d1f4f', '#6d6f9f']
            //    }
            //},
            xAxis3D: {
                min: Math.floor(xAxisMin),
                max: Math.ceil(xAxisMin + range),
                name: xAxisName,
                type: 'value',
                axisLabel: {
                    formatter: '{value} m'
                },
                splitLine: {
                    show: true
                },
                splitArea: {
                    show: true
                }
            },
            yAxis3D: {
                min: Math.floor(yAxisMin),
                max: Math.ceil(yAxisMin + range),
                name: yAxisName,
                type: 'value',
                axisLabel: {
                    formatter: '{value} m'
                },
                splitLine: {
                    show: true
                },
                splitArea: {
                    show: true
                }
            },
            zAxis3D: {
                max: 0,
                min: Math.floor(-range),
                name: zAxisName,
                type: 'value',
                axisLabel: {
                    formatter: '{value} m'
                },
                splitLine: {
                    show: true
                },
                splitArea: {
                    show: true
                }
            },
            grid3D: {
                viewControl: {
                    autoRotate: true,
                    distance: 250,
                    autoRotateAfterStill: 10,
                    //projection: 'orthographic',
                    //orthographicSize: 200
                }
            },
            series: seriesCollection.map((series, index) => {
                return {
                    name: series.name,
                    type: 'line3D',
                    data: series.points,
                    lineStyle: {
                        width: 3
                    }
                }
            })

            //[{
            //    name: seriesCollection[0].name,
            //    type: 'line3D',
            //    data: seriesCollection[0].points,
            //    lineStyle: {
            //        width: 3
            //    }
            //},
            //    {
            //        name: seriesCollection[0].name,
            //        type: 'scatter3D',
            //        data: seriesCollection[0].points,
            //        symbolSize: 8,
            //        itemStyle: {
            //            opacity: 0.6
            //        },
            //        emphasis: {
            //            itemStyle: {
            //                color: '#fff',
            //                opacity: 1,
            //                borderColor: '#fff',
            //                borderWidth: 2
            //            }
            //        }
            //    }]
        };
        chart.setOption(option);
    }

    useEffect(() => {
        handleComponentLoaded();
    }, [seriesCollection]);

    return (
        <div id="main" style={{ width: '100%', height: 600 }}></div>
    )
}