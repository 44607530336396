import React from 'react';
import ReactEcharts from 'echarts-for-react';

export const ScatterChart = ({ title, xAxisName, yAxisName, seriesName, seriesData, height = 600 }) => {
    const option = {
        title: {
            text: title,
            left: 'center'
        },
        tooltip: {
            show: false
        },
        toolbox: {
            feature: {
                saveAsImage: {},
                dataZoom: {},
                dataView: {}
            }
        },
        xAxis: {
            name: xAxisName,
            type: 'value',
            scale: true,
            splitLine: {
                show: true
            }
        },
        yAxis: {
            name: yAxisName,
            type: 'value',
            scale: true,
            splitLine: {
                show: true
            }
        },
        series: [{
            name: seriesName,
            type: 'scatter',
            data: seriesData
        }]
    };

    return (
        <ReactEcharts
            option={option}
            style={{ height: height }}
        />
    );
}