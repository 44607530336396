import React, { useState, useEffect } from 'react';
import { CalendarChart } from '../../widgets/CalendarChart';


export const HTDayPicker = ({ startDate, endDate, recordDates, handleDateChosen }) => {
    const [start, setStart] = useState(startDate);
    const [end, setEnd] = useState(endDate);
    const [dates, setDates] = useState(recordDates);
    const [chosenDate, setChosenDate] = useState(null);

    useEffect(() => {
        setStart(startDate);
        setEnd(endDate);
        setChosenDate(endDate);
        console.log('startDate', startDate);
        console.log('endDate', endDate);
    }, [startDate, endDate]);

    const handleDateChanged = (date) => {
        setChosenDate(date);
        handleDateChosen(date);
    }


    return (
        <div className="card">
            <div className="card-header">
                <h3>Date Range</h3>
            </div>
            <div className="card-body">
                <div className="form-group">
                    <div className="bg-light text-dark">
                        {startDate && endDate && < CalendarChart title="test" startDate={start} endDate={end} recordDates={dates} height={240} handleDateChosen={handleDateChanged} />}
                    </div>
                </div>
            </div>
        </div>
    );
};
