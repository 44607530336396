import React from 'react';
import ReactEcharts from 'echarts-for-react';

function getVirtualData(dates) {
    //const date = new Date(startDate);
    //const end = new Date(endDate);
    //const dayTime = 3600 * 24 * 1000;
    const data = dates.map(date => {
        return [date, 1];
    });
    return data;
}


export const CalendarChart = ({ title, startDate, endDate, recordDates, height = 300, handleDateChosen }) => {
    let calendarStart = new Date(startDate);
    calendarStart.setDate(calendarStart.getDate() - 7);
    let calendarEnd = new Date(endDate);
    calendarEnd.setDate(calendarEnd.getDate() + 7);
    const option = {
        tooltip: {
            trigger: 'item',
            formatter: function (params) {
                var value = params.value;
                var date = new Date(value[0]);
                return date.toDateString();
            }
        },
        calendar: {
            top: 40,
            right: 20,
            bottom: 20,
            left: 60,
            yearLabel: {
                margin: 30
            },
            monthLabel: {
                nameMap: 'cn',
                margin: 15
            },
            dayLabel: {
                margin: 15,
                firstDay: 1,
                nameMap: 'cn'
            },
            cellSize: ['auto', 'auto'],
            range: [calendarStart, calendarEnd],
            itemStyle: {
                borderWidth: 0.5
            }
        },
        series: {
            type: 'graph',
            coordinateSystem: 'calendar',
            data: getVirtualData(recordDates)
        }
    };

    const events = {
        click: (params) => {
            if (params.dataType === 'node') {
                if (params.data !== undefined) {
                    if (params.data[0] !== undefined) {
                        handleDateChosen(params.data[0]);
                    }
                }
            }

        }
    };


    return (
        <ReactEcharts
            option={option}
            onEvents={events}
            style={{ height: height }} />
    );
}
