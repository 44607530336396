import React from 'react';
import ReactEcharts from 'echarts-for-react';

export const TwoSeriesTimeBasedChart = ({ title, yAxis1Name, yAxis2Name, series1Name, series1Data, series2Name, series2Data, height = 600 }) => {
    const option = {
        title: {
            text: title,
            left: 'center'
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        toolbox: {
            feature: {
                saveAsImage: {},
                dataZoom: {},
                dataView: {}
            }
        },
        xAxis: {
            type: 'time',
            scale: true,
            splitLine: {
                show: true
            }
        },
        yAxis: [{
            name: yAxis1Name,
            type: 'value',
            scale: true,
            axisLabel: {
                formatter: '{value}'
            },
            splitLine: {
                show: true
            }
        },
        {
            name: yAxis2Name,
            type: 'value',
            scale: true,
            axisLabel: {
                formatter: '{value}'
            },
            splitLine: {
                show: true
            }
        }
        ],
        series: [{
            name: series1Name,
            type: 'line',
            smooth: false,
            data: series1Data,
            yAxisIndex: 0
        },
        {
            name: series2Name,
            type: 'line',
            smooth: false,
            data: series2Data,
            yAxisIndex: 1
        }]
    };

    return (
        <ReactEcharts
            option={option}
            style={{ height: height }} />
    );
}