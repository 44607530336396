import React from 'react';
import ReactEcharts from 'echarts-for-react';

export const TimeBasedChart = ({ title, xAxisName, yAxisName, seriesName, seriesData, height=600 }) => {
    const option = {
        title: {
            text: title,
            left: 'center'
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        toolbox: {
            feature: {
                saveAsImage: {},
                dataZoom: {},
                dataView: {}
            }
        },
        xAxis: {
            type:'time',
            name: xAxisName,
            scale: true,
            splitLine: {
                show: true
            }
        },
        yAxis: {
            name: yAxisName,
            type: 'value',
            scale: true,
            axisLabel: {
                formatter: '{value}'
            },
            splitLine: {
                show: true
            }
        },
        series: [{
            name: seriesName,
            type: 'line',
            smooth: false,
            data: seriesData
        }]
    };

    return (
        <ReactEcharts
            option={option}
            style={{ height: height }} />
    );
}