import { Home } from "./components/Home";
import { LogIn } from "./components/LogIn";
import { CustomerOverview } from "./components/CustomerOverview";
import { FileBrowser } from "./components/pages/FileBrowser";
import { FileUpload } from "./components/pages/FileUpload";
import { LogOut } from "./components/pages/LogOut";
import { SurveyApproval } from "./components/pages/SurveyApproval";
import { HammerTrak } from "./components/pages/HammerTrak";
import {HammerTrakPortal} from './components/pages/HammerTrakPortal';

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/index',
        element: <Home />
    },
    {
        path: '/login',
        element: <LogIn />
    },
    {
        path: '/overview',
        element: <CustomerOverview />
    },
    {
        path: '/files',
        element: <FileBrowser />
    },
    {
        path: '/logout',
        element: <LogOut />
    },
    {
        path: '/upload',
        element: <FileUpload />
    },
    {
        path: '/approval',
        element: <SurveyApproval />
    },
    {
        path: '/hammertrak',
        element: <HammerTrak />
    },
    {
        path: 'hammertrak/portal',
        element: <HammerTrakPortal />
    }
];

export default AppRoutes;
