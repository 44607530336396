import React, { useState, useEffect } from 'react';
import { HTRigList } from '../controls/HTRigList';
import { HTDayPicker } from '../controls/hammertrak/HTDayPicker';
import { HTMapView } from '../controls/hammertrak/HTMapView';
import { HTLiveView } from '../controls/hammertrak/HTLiveView';
import { HTAnalysisView } from '../controls/hammertrak/HTAnalysisView';

export const HammerTrakPortal = () => {
    const [rigName, setRigName] = useState(null);
    const [rigSummary, setRigSummary] = useState(null);
    const [rigLocations, setRigLocations] = useState([]);
    const [chosenDate, setChosenDate] = useState(null);

    const handleRigExplore = (rig) => {
        setRigName(rig);
    }

    const handleDateChosen = (date) => {
        setChosenDate(date);
        console.log('portal' + date);
    }


    const loadSurveys = async () => {
        const response = await fetch('api/hammertrak/rigs/' + rigName);
        const data = await response.json();
        data.firstSurveyTime = data.firstSurveyTime.split('.')[0];
        data.lastSurveyTime = data.lastSurveyTime.split('.')[0];
        setRigSummary(data);
        setChosenDate(data.lastSurveyTime);
        setRigLocations(data.locationRecords);
    }

    useEffect(() => {
        if (rigName) {
            setRigSummary(null);
            setRigLocations([]);
            loadSurveys();
        }
    }, [rigName]);




    return (
        <div>
            <div className="container">
                <div className="row">
                    <HTRigList handleRigExplore={handleRigExplore} />
                </div>
                <br ></br>
                <div className="row">
                    <div className="col-md-4">
                        <div className="container">
                            <div className="row">
                                {rigName && rigSummary && <HTDayPicker startDate={rigSummary.firstSurveyTime} endDate={rigSummary.lastSurveyTime} recordDates={rigSummary.recordDates} handleDateChosen={handleDateChosen} />}
                            </div>
                            <div className="row">
                                {rigLocations.length > 0 && <HTMapView locations={rigLocations} />}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="container">
                            <div className="row">
                                {rigName && <HTAnalysisView rigName={rigName} searchDate={chosenDate} />}
                            </div>
                            <div className="row">
                                {/*{rigName && <HTLiveView efficiency={60} battery={4.05} />}*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}