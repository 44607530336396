import React, { useState, useEffect } from 'react';
import { TimeBasedChart } from '../TimeBasedChart';
import { TwoSeriesTimeBasedChart } from '../charts/TwoSeriesTimeBasedChart';
import { ScatterChart } from '../charts/ScatterChart';
import { PieChart } from '../charts/PieChart';


export const HTAnalysisView = ({ rigName, searchDate }) => {
    const [surveyData, setSurveyData] = useState(null);
    const [searchDateIsToday, setSearchDateIsToday] = useState(false);
    const [timeSinceUpdate, setTimeSinceUpdate] = useState(null);
    const [lastSampleTime, setLastSampleTime] = useState(null);
    const [lastPollTime, setLastPollTime] = useState(null);
    const [drillingTimeData, setDrillingTimeData] = useState([1, 1]);

    const loadSurvey = async () => {
        if (!searchDate) return;
        const response = await fetch('api/hammertrak/surveys/' + rigName + '/' + searchDate.split('T')[0].replaceAll('-', ''));
        const data = await response.json();
        let drillingTime = 0;
        let nonDrillingTime = 0;
        data.forEach(survey => {
            if (survey.efficiency === 0) {
                survey.primaryFrequency = 0;
                nonDrillingTime += 1;
            }
            else {
                drillingTime += 1;
            }
        });
        setDrillingTimeData([drillingTime, nonDrillingTime]);
        setSurveyData(data);
        let maxSampleTime = new Date(data[data.length - 1].sampleTimeEnd);
        setLastSampleTime(maxSampleTime);
        setLastPollTime(new Date());
    }

    useEffect(() => {

        setSurveyData(null);
        loadSurvey();
    }, [rigName, searchDate]);

    useEffect(() => {
        let now = new Date();
        let difference = now - new Date(searchDate);
        setSearchDateIsToday(difference < 86400000);
        console.log('now', now);
        console.log('lastPollTime', lastPollTime);
        let timeSincePoll = Math.floor((now - lastPollTime) / 1000);
        console.log('timeSincePoll', timeSincePoll);
        setTimeSinceUpdate(timeSincePoll);
        console.log('chosenDate', searchDate);
        if (searchDateIsToday && lastSampleTime && surveyData) {
            const interval = setInterval(() => {
                let now = new Date();
                let timeSincePoll = Math.floor((now - lastPollTime) / 1000);
                setTimeSinceUpdate(timeSincePoll);
                if (timeSincePoll > 60) {
                    setLastPollTime(new Date());
                    loadSurvey();
                }
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [searchDate, lastSampleTime]);



    let content = <p>Loading ...</p>;
    if (surveyData) {
        content = (
            <div className="row">
                <div className="col-md-6">
                    <TimeBasedChart title='Efficiency' yAxisName='Efficiency' height={300} seriesName={rigName}
                        seriesData={surveyData && surveyData.map((survey) => [survey.sampleTimeEnd.split('.')[0], survey.efficiency])} />
                </div>
                <div className="col-md-6">
                    <TimeBasedChart title='Frequency' yAxisName='Frequency' height={300} seriesName={rigName}
                        seriesData={surveyData && surveyData.map((survey) => [survey.sampleTimeEnd.split('.')[0], survey.primaryFrequency])} />
                </div>
                <div className="col-md-6">
                    <TimeBasedChart title='Rotation Rate' yAxisName='Rotation Rate' height={300} seriesName={rigName}
                        seriesData={surveyData && surveyData.map((survey) => [survey.sampleTimeEnd.split('.')[0], survey.rotationRate])} />
                </div>
                <div className="col-md-6">
                    <TwoSeriesTimeBasedChart title='Temperature' yAxis1Name='Battery' yAxis2Name='Temperature' height={300} series1Name='Battery'
                        series1Data={surveyData && surveyData.map((survey) => [survey.sampleTimeEnd.split('.')[0], survey.batteryVoltage])} series2Name='Temperature'
                        series2Data={surveyData && surveyData.map((survey) => [survey.sampleTimeEnd.split('.')[0], survey.temperature])} />
                </div>
                <div className="col-md-6">
                    <PieChart title='Drilling Time' height={300} seriesData={[{ value: drillingTimeData[0], name: 'Drilling' }, { value: drillingTimeData[1], name: 'Non-Drilling' }]} />
                </div>
                <div className="col-md-6">
                    <ScatterChart title='Efficiency vs Frequency' xAxisName='Frequency' yAxisName='Efficiency' height={300} seriesName='Efficiency vs Frequency'
                        seriesData={surveyData && surveyData.map((survey) => [survey.primaryFrequency, survey.efficiency])} />
                </div>
            </div>
        );
    }

    return (
        <div className="card">
            <div className="card-header">
                <h3>Analysis - {searchDate ? new Date(searchDate.split('T')[0]).toDateString() : "Select date to analyse"}</h3>
                {searchDateIsToday && lastSampleTime && surveyData && <p>Live - last update {lastSampleTime.toLocaleTimeString()} refreshing in {timeSinceUpdate <= 60 ? 60 - timeSinceUpdate : 0} seconds</p>}
            </div>
            <div className="card-body">
                <div className="bg-light text-dark">
                    {content}
                </div>
            </div>
        </div>
    );
}
