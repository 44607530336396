import React, { useState } from 'react';

export const FileUpload = () => {
    const [file, setFile] = useState('');
    const [fileName, setFileName] = useState('Choose File');
    const [uploadedFile, setUploadedFile] = useState(null);

    const onFileInputChange = e => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
    }

    const onSubmit = async e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', file);
        try {
            const res = await fetch('api/Upload', {
                method: 'POST',
                body: formData
            });
            const data = await res.json();
            console.log(JSON.stringify(data, null, 2));
            setUploadedFile(data);
            setFile('');
            setFileName('Choose File');
        } catch (err) {
            console.log(err);
        }
    }

    let fileData = '';
    if (uploadedFile) {
        fileData = (
            <div>
                <h3 className="text-center">{uploadedFile.fileName}</h3>
            </div>
        );
    }

    return (
        <div>
        <h1>Survey Upload</h1>
            <form onSubmit={onSubmit}>
                <div className="custom-file">
                    <label className="custom-file-label" htmlFor="customFile">{fileName}</label>
                    <br />
                    <input type="file" className="custom-file-input" id="customFile" onChange={onFileInputChange} />
                </div>
                <input type="submit" value="Upload" className="btn btn-primary btn-block mt-4" />
            </form>
            <hr />
            {fileData}
        </div>
    );

}