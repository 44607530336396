import React from 'react';
import ReactEcharts from 'echarts-for-react';

export const PieChart = ({ title, seriesData, height = 600 }) => {
    const option = {
        title: {
            text: title,
            left: 'center'
        },
        tooltip: {
            show: false,
            trigger: 'item',
            formatter: '{b} : ({d}%)'
        },
        legend: {
            orient: 'horizontal',
            left: 'center',
            top: 'bottom',
            data: seriesData.map((item) => item.name)
        },
        series: [
            {
                name: title,
                type: 'pie',
                radius: ['40%','70%'],
                center: ['50%', '50%'],
                data: seriesData,
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    },
                    label: {
                        show: true,
                        fontSize: 32,
                        fontWeight: 'bold',
                        formatter: '{b}\n{d}%'
                    }
                }
            }
        ]
    };

    return (
        <ReactEcharts option={option} style={{ height: height }} />
    );
}