import React, { useEffect } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import ReactDOM from 'react-dom';
import { MapContainer, TileLayer, Marker, Popup, Polyline, useMap } from 'react-leaflet';

export const InfoTab = ({ drillhole, handleDrillholeDelete, handleDrillholeEdit }) => {

    function ChangeMapView({ coords }) {
        const map = useMap();
        map.setView(coords, map.getZoom());

        return null;
    }

    return (
        <div className="container" style={{ marginTop: 10 }}>
            <div className="row">
                <div className="col-lg-12">
                    <div className="d-flex">
                        <h3>Info</h3>
                        <button className="btn btn-primary ms-auto" style={{ marginRight: 10 }} onClick={() => { handleDrillholeEdit(drillhole) }}><FaEdit /> Edit</button>
                        <button className="btn btn-danger" style={{ marginRight: 10 }} onClick={() => { handleDrillholeDelete(drillhole) }}><FaTrash /> Delete</button>
                    </div>
                </div>
                <div className="col-lg-12">
                    <table className="table table-sm">
                        <thead>
                            <tr>
                                <th>Rig</th>
                                <th>Completed</th>
                                <th>Created</th>
                                <th>X</th>
                                <th>Y</th>
                                <th>Z</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{drillhole.rigId}</td>
                                <td>{drillhole.isCompleted ? 'Yes' : 'No'}</td>
                                <td>{new Date(drillhole.created).toLocaleDateString()}</td>
                                <td>{drillhole.location.x}</td>
                                <td>{drillhole.location.y}</td>
                                <td>{drillhole.location.z}</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>Description: {drillhole.description || 'Empty'}</p>
                    <div style={{ width: '100%', height: 500 }}>
                        <MapContainer center={[drillhole.location.x, drillhole.location.y]} zoom={12}>
                            <TileLayer
                                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={[drillhole.location.x, drillhole.location.y]}>
                                <Popup>
                                    {drillhole.name} <br /> {drillhole.location.x} , {drillhole.location.y} , {drillhole.location.z}
                                </Popup>
                            </Marker>
                            {/*<Polyline positions={[[drillhole.location.x, drillhole.location.y], [drillhole.location.x - 0.003, drillhole.location.y + 0.001]]} />*/}
                            <ChangeMapView coords={[drillhole.location.x, drillhole.location.y]}/>
                        </MapContainer>
                    </div>
                </div>
            </div>
        </div>
    );
}