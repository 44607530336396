import React, { useState, useEffect } from 'react';

export const FileList = ({ customer = '', handleFileOpen }) => {
    const [files, setFiles] = useState([]);
    const [displayedFiles, setDisplayedFiles] = useState([files]);

    useEffect(() => {
        const loadFiles = async () => {
            const response = await fetch(`api/Repository?customer=${customer}`);
            const data = await response.json();
            setFiles(data);
            setDisplayedFiles(data);
        }
        loadFiles();
    }, [customer]);

    const handleSearch = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        const filteredFiles = files.filter(file => file.key.toLowerCase().includes(searchTerm));
        setDisplayedFiles(filteredFiles);
    }

    if (files == null || files.length === 0) {
        return <p>Loading ...</p>;
    }

    return (
        <div>
            <h2>Surveys</h2>
            {customer && <h3>Customer: {customer}</h3>}
            <input type="text" className="form-control" placeholder="Search" onChange={handleSearch} />
            <table className='table table-sm table-striped'>
                <thead>
                    <tr>
                        <th>File Name</th>
                        <th>File Size</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {displayedFiles.filter(file => file.key.includes('.csv') || file.key.includes('/surveys/')).map(file =>
                        <tr key={file.key}>
                            <td>{file.key}</td>
                            <td>{file.size}</td>
                            <td><button className='btn btn-primary btn-sm' onClick={() => { handleFileOpen(file.key) }} >Open</button></td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}