import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CustomerList } from './controls/CustomerList';
import { ProjectList } from './controls/ProjectList';
import { ProjectSummary } from './controls/ProjectSummary';
import { DrillholeSummary } from './controls/DrillholeSummary';
import { checkLoggedIn } from '../functions/CookieHelper';


export const CustomerOverview = () => {
    const [loggedIn, setLoggedIn] = React.useState(checkLoggedIn());
    const [loading, setLoading] = useState(true);
    const [customerMap, setCustomerMap] = useState({});
    const [selectedProject, setSelectedProject] = useState({});
    const [selectedDrillhole, setSelectedDrillhole] = useState({});

    const handleProjectSelect = (projectName) => {
        let project = customerMap.projects.find((project) => project.name === projectName);
        setSelectedProject(project);
        setSelectedDrillhole({});
    }

    const handleProjectDelete = (projectName) => {
        fetch('api/projects/deleteproject', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'ProjectName': projectName
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', JSON.stringify(data, null, 2));
                handleMapUpdated(data);
            });
    }

    const handleDrillholeSelect = (drillholeName) => {
        let drillhole = selectedProject.drillholes.find((drillhole) => drillhole.name === drillholeName);
        setSelectedDrillhole(drillhole);
    }

    const handleMapUpdated = (newMap) => {
        setCustomerMap(newMap);
        setSelectedProject({});
        setSelectedDrillhole({});
    }

    useEffect(() => {
        if (!loggedIn) {
            return;
        }
        setLoading(true);
        setCustomerMap({});
        setSelectedProject({});
        const loadCustomer = async () => {
            const response = await fetch('api/projects');
            const data = await response.json();
            setCustomerMap(data);
            setLoading(false);
        }
        loadCustomer();

    }, []);


    return loggedIn ? (
        <div className="container-fluid overflow-auto">
            <div className="row" style={{ height: 0 }}></div>
            {loading ? <p><em>Loading projects...</em></p> :
                (
                    <div className="row">
                        <div className="col-md-4  col-lg-3">
                            {customerMap.logoUrl && <img src={customerMap.logoUrl} alt="Logo" style={{ width: '100%', height: 64, margin: 5 }} />}
                            <ProjectList projects={customerMap.projects} handleProjectSelect={handleProjectSelect} handleMapUpdated={handleMapUpdated} />
                            <hr />
                            <ProjectSummary project={selectedProject} handleDrillholeSelect={handleDrillholeSelect} handleMapUpdated={handleMapUpdated} handleProjectDelete={handleProjectDelete} />
                            <hr style={{ marginTop: 10 }} />
                        </div>
                        <div className="col-md-8 col-lg-9">
                            <DrillholeSummary drillhole={selectedDrillhole} />
                        </div>
                    </div>
                )
            }
        </div>
    ) : (<div>
        <h1>Not Logged in</h1>
        <p>Please <Link to="/login?returnPage=/overview">login</Link></p>
    </div>)
}