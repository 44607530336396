import React, { useState } from 'react';
import { FileList } from '../controls/FileList';
import { SurveyTable } from '../controls/SurveyTable';

export const FileBrowser = () => {
    const [file, setFile] = useState(null);
    const customer = sessionStorage.getItem('customer');

    const handleFileOpen = (file) => {
        console.log(file);
        setFile(file);
    }

    return (
        <div>
            <h1>File Browser</h1>
            <FileList customer={customer ?? ''} handleFileOpen={handleFileOpen} />
            {file && <SurveyTable fileName={file} />}
            <div id="surveyView"></div>
        </div>
    );
}
