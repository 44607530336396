import React, { useState, useEffect } from 'react';

export const SurveyTable = ({ fileName }) => {
    const [survey, setSurvey] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        const loadSurvey = async () => {
            const response = await fetch(`api/Repository/Survey?file=${fileName}`);
            const data = await response.json();
            setSurvey(data);
            setLoading(false);
        }
        loadSurvey();
    }, [fileName]);

    let contents = '';

    if (loading) {
        contents = <p><em>Loading...</em></p>;
    }
    else {
        contents = (
            <div>
                <div className="overflow-auto" style={{ maxHeight: 500 }}>
                    <table className='table table-sm table-striped'>
                        <thead>
                            <tr>
                                {survey.headers.map(header =>
                                    <th key={header}>{header}</th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {survey.rows.map((row, i) =>
                                <tr key={i}>
                                    {row.map((cell, j) =>
                                        <td key={`${i}_${j}`}>{cell}</td>
                                    )}
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }


    return (
        <div>
            <h3>{fileName}</h3>
            {contents}
        </div>
    );

}