import React from 'react';
import { checkLoggedIn, logUserOut } from '../../functions/CookieHelper';

export const LogOut = () => {
    const [loggedIn, setLoggedIn] = React.useState(checkLoggedIn());


    const handleLogOut = () => {
        fetch('api/identity/logout', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', JSON.stringify(data, null, 2));
                setLoggedIn(false);
                logUserOut();
                //sleep for 1 second before redirecting
                setTimeout(() => {
                    document.location.href = '/';
                }, 1000);
            });
    }

    React.useEffect(() => {
        handleLogOut();
    }, []);

    return (<div>
        {loggedIn ? < h1 > Logging out...</h1 > : < h1 > Logged out</h1 >}
    </div>
    );
}