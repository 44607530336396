import React from 'react';
import {checkLoggedIn} from '../functions/CookieHelper';

export const LogIn = () => {

    //get the returnPage from the query string
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const returnPage = urlParams.get('returnPage');

    const [loggedIn, setLoggedIn] = React.useState(checkLoggedIn());
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [authorising, setAuthorising] = React.useState(false);

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (username === '' || password === '') {
            alert('Please enter a username and password');
            return;
        }
        const loginParams = {
            username: username,
            password: password
        };
        setAuthorising(true);
        fetch('api/identity/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(loginParams)
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    console.log('Success:', JSON.stringify(data, null, 2));
                    setLoggedIn(true);
                    document.location.href = returnPage ? returnPage : '/';

                }
                else {
                    console.log('Failure:', JSON.stringify(data, null, 2));
                    alert(data.message);
                }

                setAuthorising(false);
                setPassword('');
            })
            .catch((error) => {
                console.error('Error:', error);
                setAuthorising(false);
                setPassword('');
            }
            );

    }

    const logInForm = (
        <div className='col-md-3 col-lg-2'>
            <h2>Log In</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Name:</label>
                    <input type="text" className="form-control" id="name" name="name" value={username} onChange={handleUsernameChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password:</label>
                    <input type="password" className="form-control" id="password" name="password" value={password} onChange={handlePasswordChange} />
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
        </div>
    );

    return (
        <div className='d-flex justify-content-center'>
            {loggedIn ? <h1>Logged In</h1> : authorising ? <h1>Logging in...</h1> : logInForm}
        </div>
    );
}