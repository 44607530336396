import React, { useState } from 'react';

export const SurveyApprovalForm = ({ customerMap, selectedSurvey, handleApproveSubmit }) => {
    const [selectedProject, setSelectedProject] = useState('');
    const [selectedDrillhole, setSelectedDrillhole] = useState('');

    const handleProjectChange = (event) => {
        if (event.target.value === '') {
            return;
        }
        setSelectedProject(event.target.value);
        setSelectedDrillhole('');
    }

    const handleDrillholeChange = (event) => {
        setSelectedDrillhole(event.target.value);
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if (!selectedProject || !selectedDrillhole) {
            return;
        }
        handleApproveSubmit(selectedProject, selectedDrillhole);
    }

    return (
        <form onSubmit={handleFormSubmit}>
            <div className="form-group">
                <label htmlFor="projectSelect">Project</label>
                <select className="form-select" id="projectName" value={selectedProject} onChange={handleProjectChange}>
                    <option value="">Select a project</option>
                    {customerMap.projects && customerMap.projects.map((project) => {
                        return <option key={project.name} value={project.name}>{project.name}</option>;
                    })}
                </select>
            </div>
            <div className="form-group">
                <label htmlFor="drillholeSelect">Drillhole</label>
                <select className="form-select" id="drillholeName" value={selectedDrillhole} onChange={handleDrillholeChange}>
                    <option value="">Select a drillhole</option>
                    {customerMap.projects && customerMap.projects.find(p => p.name === selectedProject) && customerMap.projects.find(p => p.name === selectedProject).drillholes.map((drillhole) => {
                        return <option key={drillhole.name} value={drillhole.name}>{drillhole.name}</option>;
                    })}
                </select>
            </div>
            <button type="submit" className="btn btn-primary" disabled={!selectedSurvey.key || !selectedProject || !selectedDrillhole}>Approve</button>
        </form>
    );
}