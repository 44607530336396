import React, { useState, useEffect } from 'react';
import { FaTrash, FaShare } from 'react-icons/fa';

export const TableTab = ({ drillhole }) => {
    const [loading, setLoading] = useState(false);
    const [selectedSurvey, setSelectedSurvey] = useState('');
    const [survey, setSurvey] = useState({});


    const handleSurveyDelete = () => {
        if (window.confirm('Are you sure you want to delete this survey?')) {
            alert('delete survey ' + selectedSurvey + ', coming soon');
        }
    }

    const handleSurveyShare = () => {
        alert('share survey ' + selectedSurvey + ', coming soon');
    }

    const handleSurveySelect = (event) => {
        if (event.target.value === '') {
            return;
        }
        setSelectedSurvey(event.target.value);
        const surveyReference = drillhole.surveys.find(survey => survey.fileName === event.target.value);
        loadSurvey(surveyReference);
    }

    const loadSurvey = async (surveyReference) => {
        if (!surveyReference) {
            return;
        }
        setLoading(true);
        const response = await fetch('api/surveys?key=' + surveyReference.key, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        setSurvey(data);
        setLoading(false);
    }


    useEffect(() => {
        setLoading(false);
        setSelectedSurvey('');
        setSurvey({});
    }, [drillhole]);

    const renderSurveyTable = (survey) => {
        return survey.surveyLines && (
            <table className="table table-striped table-sm">
                <thead>
                    <tr>
                        <th>Depth</th>
                        <th>Inclination</th>
                        <th>Azimuth</th>
                        <th>North</th>
                        <th>East</th>
                        <th>Tvd</th>
                    </tr>
                </thead>
                <tbody>
                    {survey.surveyLines.map((row, index) => {
                        return <tr key={index}>
                            <td>{row.depth.toFixed(2)}</td>
                            <td>{row.inclination.toFixed(2)}</td>
                            <td>{row.azimuth.toFixed(2)}</td>
                            <td>{row.northing.toFixed(2)}</td>
                            <td>{row.easting.toFixed(2)}</td>
                            <td>{row.tvd.toFixed(2)}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        )
    }


    return (
        <div className="container" style={{ marginTop: 10 }}>
            <div className="row">
                <div className="col-lg-12">
                    <div className="d-flex">
                        <h3>Data Tables</h3>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="d-flex">
                        <div >
                            <select className="form-select form-select-sm" value={selectedSurvey} onChange={handleSurveySelect} style={{ minWidth: 300 }}>
                                <option value=''>Select a Survey</option>
                                {drillhole.surveys && drillhole.surveys.map((survey, index) => {
                                    return <option key={index} value={survey.fileName}> {survey.fileName} </option>
                                })}
                            </select>
                        </div>
                        {selectedSurvey !== '' && (
                            <div className="ms-auto">
                                <button className="btn btn-primary" style={{ marginRight: 10 }} onClick={() => handleSurveyShare()}><FaShare /> Share</button>
                                <button className="btn btn-danger" onClick={() => handleSurveyDelete()}><FaTrash /> Delete</button>
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="overflow-auto" style={{ maxHeight: 400 }}>
                        {loading ? <p><em>Loading...</em></p> : renderSurveyTable(survey)}
                    </div>
                </div>
            </div>
        </div>
    );
}